import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "@emotion/styled";

const StyledContent = styled.div`
  @media (min-width: 1023px) {
    .flip-card:hover .flip-card-inner {
      transform: rotateY(180deg);
    }
  }

  .flip-card-inner {
    transition: transform 0.6s;
    transform-style: preserve-3d;
    &.active {
      transform: rotateY(180deg);
    }
  }

  .flip-card-front,
  .flip-card-back {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .flip-card-back {
    transform: rotateY(180deg);
  }
`;

const ValueProps = ({ className }) => {
  const [flipCard, setFlipCard] = useState("");

  const handleFlipCard = (i) => {
    setFlipCard(i);
  };

  const data = useStaticQuery(graphql`
    {
      icon1: file(
        relativePath: { eq: "repeating/value-props/Net Promoter Score.svg" }
      ) {
        publicURL
      }
      icon2: file(
        relativePath: { eq: "repeating/value-props/Reviews.svg" }
      ) {
        publicURL
      }
      icon3: file(
        relativePath: { eq: "repeating/value-props/Retention Rate.svg" }
      ) {
        publicURL
      }
    }
  `);

  const content = [
    {
      icon: data.icon1.publicURL,
      number: "94%",
      heading: "Net Promoter Score",
      excerpt:
        "The average NPS for accounting firms is 38%. At Dark Horse, 94% of our clients would recommend us to a friend.",
      text: 'Companies use this metric to gauge how happy their customers are with their services. It\'s calculated by taking the percentage of "promoters" minus the % of "detractors."The average NPS score for the accounting industry is 39%, less than the DMV! We believe it\'s important to share that we have successfully fulfilled our promise to WOW clients and consistently monitor this metric to ensure we continue to deliver on the highest level.',
    },
    {
      icon: data.icon2.publicURL,
      number: "300+",
      heading: "5-Star Reviews",
      excerpt:
        "Our Google and Yelp reviews speak for themselves. Our goal is to provide 5-star service to every client, every time.",
      text: "When someone shares their experience with us online, we find it extremely valuable to know where we've exceeded expectations and where we can improve. Many of our clients decide to engage with us as a result of reading about other Dark Horse clients’ experiences.",
    },
    {
      icon: data.icon3.publicURL,
      number: "92%",
      heading: "Retention Rate",
      excerpt:
        "We're all about creating long-lasting, value-added relationships.",
      text: "This is the frequency of repeat business from our clients that work with us. This means that 9 out of 10 clients use us for subsequent years. That shows us that people enjoy working with our CPAs, are pleased with the work, and want to continue with Dark Horse over the long term.",
    },
  ];

  return (
    <section className={`${className || "mb-20 md:mb-20 lg:mb-20"}`}>
      <div className="container mt-[128px]">
        {/* <Fade bottom cascade distance="120px" duration={1500}> */}
        <StyledContent>
          <div className="grid grid-cols-1 gap-y-8 lg:grid-cols-3 lg:gap-x-6 xl:gap-x-10">
            {content.map((content, i) => {
              return (
                <div key={i} className="flip-card">
                  <div
                    className={`flip-card-inner relative h-full min-h-[360px] lg:min-h-[332px] ${
                      flipCard === i ? "active" : ""
                    }`}
                  >
                    <div className="flip-card-front relative h-full w-full rounded-4xl bg-green-800 p-6 lg:pb-8 lg:pt-8 xl:pt-10">
                      <img
                        src={content.icon}
                        alt={content.heading}
                        className="mb-4 xl:mb-5"
                      />
                      <div>
                        <div className="mb-4 text-5xl font-bold text-white">
                          {content.number}
                        </div>
                        <p className="heading-five text-white">
                          {content.heading}
                        </p>
                        <p className="mb-3.5 lg:mb-0 text-white opacity-70">{content.excerpt}</p>

                        <button
                          className="absolute right-4 bottom-4 ml-auto flex h-10 w-10 items-center justify-center rounded-full bg-primary-500 no-underline shadow-5xl lg:hidden"
                          onClick={() => handleFlipCard(i)}
                        >
                          <i className="fal fa-plus text-2xl text-white"></i>
                        </button>
                      </div>
                    </div>

                    <div
                      className={`flip-card-back absolute top-0 h-full w-full rounded-4xl bg-primary-800 px-6 pt-8 lg:pb-10 lg:pt-10`}
                    >
                      <p className="heading-five text-white">
                        {content.heading}
                      </p>
                      <p className="mb-3.5 text-sm text-white lg:mb-0">
                        {content.text}
                      </p>
                      <button
                        className="absolute right-4 bottom-4 ml-auto flex h-10 w-10 items-center justify-center rounded-full bg-primary-500 no-underline shadow-5xl lg:hidden"
                        onClick={() => handleFlipCard(i + content.length)}
                      >
                        <i className="fal fa-minus text-2xl text-white"></i>
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </StyledContent>
        {/* </Fade> */}
      </div>
    </section>
  );
};

export default ValueProps;
