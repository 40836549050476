import React, { useState } from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ValueProps from "../components/Repeating/ValueProps";
import Badges from "../components/Repeating/Badges";
import Testimonials from "../components/Repeating/Testimonials";
import FindYourCPA from "../components/Repeating/FindYourCPA";
import Locations from "../components/Repeating/Locations";
import Subscribe from "../components/Repeating/Subscribe";
import RecentPosts from "../components/Repeating/RecentPosts";
import CallToAction from "../components/Repeating/CTA";
import ModalVideo from "../components/Modal/ModalVideo";
import ButtonSolid from "../components/Button/ButtonSolid";
import ButtonWithIcon from "../components/Button/ButtonWithIcon";

const Page = ({ data }) => {
  const [autoPlay, setAutoPlay] = useState(false);

  const handlePlayVideo = () => {
    setAutoPlay(true);
  };

  return (
    <Layout>
      <SearchEngineOptimization
        title="Cannabis CPA | Marijuana Accounting | Dark Horse"
        description="Running a cannabis business can be tough, especially managing the books and taxes. Contact us today to help with all your cannabis accounting business needs!"
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="relative overflow-hidden lg:md:py-48">
        <div className="top-0 left-0 z-10 lg:absolute object-cover">
        <img src={data.hero.publicURL} alt="hero" className="object-cover lg:min-h-[700px]" />
        </div>

        <div className="absolute top-0 left-0 z-20 hidden h-full w-full bg-black/10 lg:block"></div>

        <div className="container relative z-30">
          <div className="mx-auto mt-6 max-w-4xl text-center lg:mt-0">
            <h1 className="text-mobile-6xl lg:mb-7 lg:text-6xl lg:text-white">
            Dark Horse Cannabis CPAs
            </h1>
            <p className="mb-5 lg:mb-8 lg:text-xl lg:text-white">
            Operating in the cannabis industry is challenging. We’ll help you navigate the changing regulatory landscape, deploy tax saving strategies and provide actionable accounting for your business.
            </p>
            <div className="justify-center space-y-4 md:flex md:space-x-2.5 md:space-y-0">
              
              <ButtonSolid
                href="/pricing-tool/"
                text="Get a Quote"
                altStyle={1}
                className="w-full md:w-auto"
              />
              
            </div>
          </div>
        </div>
      </section>

      

      <ValueProps className="mb-32 md:mb-44 lg:mb-60" />

      <section className="mb-20 md:mb-20">
        <div className="container">
          <div className="grid items-center gap-y-8 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div>
              <GatsbyImage image={data.intro.childImageSharp.gatsbyImageData} />
            </div>
            <div>
              <h2>Cannabis Companies Suffer From a Lack of Good CPAs</h2>
              <p>
              We believe cannabis companies are legitimate businesses. Most CPA firms don’t. Thus, the industry is chronically underserved. We’ll treat your cannabis business with the same level of care and attention to detail as any other Dark Horse business client.
              </p>
              <div className="grid items-center justify-start space-y-4 lg:flex lg:space-y-0 lg:space-x-4">
                
                <ButtonWithIcon href="/about-us/" text="Learn More" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="relative mb-20 bg-[#E9F3F5] py-20 md:mb-32 md:py-32">
            <div className="container">
              <div className="mx-auto mb-10 md:hidden">
              <GatsbyImage image={data.challenges.childImageSharp.gatsbyImageData} className="z-0 w-full rounded-4xl" />
                  
              </div>

              <header className="mb-10 text-center md:mb-18">
                <h2>Challenges Faced by Cannabis Companies</h2>
                <p className="text-center max-w-[760px] m-auto">Too many cannabis businesses lack access to the competent accounting expertise, tax planning, and CFO advisory that is more readily available to businesses outside the cannabis industry.</p>
              </header>

              <div className="grid items-center gap-y-3 md:grid-cols-3 md:gap-x-6 lg:gap-x-10">
                <div className="space-y-3 md:space-y-8">
                  
                        <div className="relative">
                          <div className="relative z-20 block rounded-xl bg-white p-4 shadow-4xl after:absolute after:top-1/2 after:-right-4 after:hidden after:h-0 after:w-0 after:-translate-y-1/2 after:border-[16px] after:border-r-0 after:border-transparent after:border-l-white md:ml-2 md:after:block lg:p-4">
                          Most CPA firms miss valuable tax deductions due to a lack of expertise in IRS § 280E

                          </div>
                          <div className="absolute left-0 top-0 hidden h-full w-full rounded-xl bg-primary-500 md:block"></div>
                        </div>
                        <div className="relative">
                          <div className="relative z-20 block rounded-xl bg-white p-4 shadow-4xl after:absolute after:top-1/2 after:-right-4 after:hidden after:h-0 after:w-0 after:-translate-y-1/2 after:border-[16px] after:border-r-0 after:border-transparent after:border-l-white md:ml-2 md:after:block lg:p-4">
                          Most bookkeepers lack understanding of proper accounting under IRS § 280E
                          </div>
                          <div className="absolute left-0 top-0 hidden h-full w-full rounded-xl bg-primary-500 md:block"></div>
                        </div>
                        <div className="relative">
                          <div className="relative z-20 block rounded-xl bg-white p-4 shadow-4xl after:absolute after:top-1/2 after:-right-4 after:hidden after:h-0 after:w-0 after:-translate-y-1/2 after:border-[16px] after:border-r-0 after:border-transparent after:border-l-white md:ml-2 md:after:block lg:p-4">
                          Few accounting firms are aware of the myriad state laws and regulations. Even fewer help clients navigate this landscape.
                          </div>
                          <div className="absolute left-0 top-0 hidden h-full w-full rounded-xl bg-primary-500 md:block"></div>
                        </div>
                        
                    
                </div>

                
                <div className="mx-auto hidden md:block">
                <GatsbyImage image={data.challenges.childImageSharp.gatsbyImageData} className="z-0 w-full rounded-4xl" />
                  
                </div>
                <div className="space-y-3 md:space-y-8">
                  
                        <div className="relative">
                          <div className="relative z-20 block rounded-xl bg-white p-4 shadow-4xl after:absolute after:top-1/2 after:-left-4 after:hidden after:h-0 after:w-0 after:-translate-y-1/2 after:border-[16px] after:border-l-0 after:border-transparent after:border-r-white md:mr-2 md:after:block lg:p-4">
                          Finding the right firm is difficult since many CPAs have stigmatized the industry
                          </div>
                          <div className="absolute left-0 top-0 hidden h-full w-full rounded-xl bg-primary-500 md:block"></div>
                        </div>

                        <div className="relative">
                          <div className="relative z-20 block rounded-xl bg-white p-4 shadow-4xl after:absolute after:top-1/2 after:-left-4 after:hidden after:h-0 after:w-0 after:-translate-y-1/2 after:border-[16px] after:border-l-0 after:border-transparent after:border-r-white md:mr-2 md:after:block lg:p-4">
                          Unlicensed professionals lack the expertise and service delivery model to appropriately advise cannabis businesses
                          </div>
                          <div className="absolute left-0 top-0 hidden h-full w-full rounded-xl bg-primary-500 md:block"></div>
                        </div>

                        <div className="relative">
                          <div className="relative z-20 block rounded-xl bg-white p-4 shadow-4xl after:absolute after:top-1/2 after:-left-4 after:hidden after:h-0 after:w-0 after:-translate-y-1/2 after:border-[16px] after:border-l-0 after:border-transparent after:border-r-white md:mr-2 md:after:block lg:p-4">
                          Of the competent cannabis CPAs, few work alongside clients as partners. Most are simply vendors.
                          </div>
                          <div className="absolute left-0 top-0 hidden h-full w-full rounded-xl bg-primary-500 md:block"></div>
                        </div>
                    
                </div>

                </div>
            </div>
          </section>

          <section className="mb-24 pt-20 md:mb-48">
        <div className="container">
        <header className="mb-10 text-left md:mb-18">
        <h2 className="max-w-[720px]">Full Spectrum Accounting, Tax, and Advisory</h2>
        <p className="max-w-[640px]">Our services include tax preparation, strategic tax planning, bookkeeping, outsourced accounting and Fraction CFO services to maximize the money your business earns and the money you keep as an owner.</p>
        </header>
          <div className="grid gap-y-10 md:grid-cols-3 md:gap-x-5 lg:gap-x-10">
            <div>
              <AniLink
                fade
                to="/cannabis-taxes/"
                className="group font-normal text-gray-700 no-underline hover:text-gray-700"
              >
                <div className="mb-5 overflow-hidden rounded-4xl">
                  <GatsbyImage
                    image={data.taxes.childImageSharp.gatsbyImageData}
                    className="w-full scale-100 transform blur-none filter transition-all duration-500 ease-linear md:group-hover:scale-105"
                  />
                </div>
                <h3>Maximize Tax Savings</h3>
                <div className="mb-0">
                Taxes can be a buzz kill. Cannabis companies are hamstrung by IRS Section 280E, but we implement tax strategies to minimize your taxes and save you money.
                </div>
              </AniLink>
            </div>
            <div>
              <AniLink
                fade
                to="/cannabis-bookkeeping/"
                className="group font-normal text-gray-700 no-underline hover:text-gray-700"
              >
                <div className="mb-5 overflow-hidden rounded-4xl">
                <GatsbyImage
                    image={data.keeping.childImageSharp.gatsbyImageData}
                    className="w-full scale-100 transform blur-none filter transition-all duration-500 ease-linear md:group-hover:scale-105"
                  />
                </div>
                <h3>Expert Bookkeeping</h3>
                <div className="mb-0">
                Your financial statements are just numbers on a page if you can't use them to make informed strategic decisions. We'll ensure you know your numbers.
                </div>
              </AniLink>
            </div>
            <div>
              <AniLink
                fade
                to="/cannabis-cfo/"
                className="group font-normal text-gray-700 no-underline hover:text-gray-700"
              >
                <div className="mb-5 overflow-hidden rounded-4xl">
                  <GatsbyImage
                    image={data.cfo.childImageSharp.gatsbyImageData}
                    className="w-full scale-100 transform blur-none filter transition-all duration-500 ease-linear md:group-hover:scale-105"
                  />
                </div>
                <h3>Next Level CFO</h3>
                <div className="mb-0">
                The best financials in the world are just numbers on a page if you can’t interpret what those numbers mean. Our CFO advisory can take your business to the next level.
                </div>
              </AniLink>
            </div>
          </div>
        </div>
      </section>

   

<Locations />

<section className="mb-24 pt-20 md:mb-48">
        
        <header className="mb-10 text-center md:mb-18">
        <h2 className="max-w-[720px] m-auto">Come One, Come All</h2>
        <p className="max-w-[640px] m-auto">We work with all businesses in the cannabis industry—from seed to sale!</p>
        </header>
          <div className="grid gap-y-0 grid-col-1 md:grid-cols-3 md:gap-x-0 lg:gap-x-0">
            
            <div className="relative">
              <AniLink
                fade
                to="/cultivators-growers-manufacturers/"
                className="group font-normal text-white no-underline"
              >
                <div className="mb-5 overflow-hidden">
                  <GatsbyImage
                    image={data.cultivators.childImageSharp.gatsbyImageData}
                    className="w-full scale-100 transform blur-none filter transition-all duration-500 ease-linear md:group-hover:scale-105"
                  />
                </div>
                <p className="text-white uppercase text-lg absolute text-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-bold">Cultivators & Growers</p>
               
              </AniLink>
            </div>
            <div className="relative">
              <AniLink
                fade
                to="/dispensaries-retail/"
                className="group font-normal text-white no-underline"
              >
                <div className="mb-5 overflow-hidden">
                  <GatsbyImage
                    image={data.dispensaries.childImageSharp.gatsbyImageData}
                    className="w-full scale-100 transform blur-none filter transition-all duration-500 ease-linear md:group-hover:scale-105"
                  />
                </div>
                <p className="text-white uppercase text-lg absolute text-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-bold">Dispensaries</p>
                
              </AniLink>
            </div>
            <div className="relative">
              <AniLink
                fade
                to="/cannabis-industry/"
                className="group font-normal text-white no-underline"
              >
                <div className="mb-5 overflow-hidden">
                  <GatsbyImage
                    image={data.industry.childImageSharp.gatsbyImageData}
                    className="w-full scale-100 transform blur-none filter transition-all duration-500 ease-linear md:group-hover:scale-105"
                  />
                </div>
                <p className="text-white uppercase text-lg absolute text-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-bold">Supporting Enterprises</p>
                
              </AniLink>
            </div>
          </div>
       
      </section>


      <Testimonials category="cannabis"/>
    
      

       <section className="mb-20 md:mb-20">
        <div className="container">
          <div className="grid items-center gap-y-8 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            
            <div>
              <h2>IRS Section 280E</h2>
              <p>
              Section 280E can profoundly impact a cannabis business’s tax bill. We maximize your deductions under 280E with IRS audit-defensible positions.
              </p>
              <div className="grid items-center justify-start space-y-4 lg:flex lg:space-y-0 lg:space-x-4">
                
                <ButtonWithIcon href="/section-280e-accountants/" text="Learn More" />
              </div>
            </div>
            <div>
              <GatsbyImage image={data.two.childImageSharp.gatsbyImageData} />
            </div>
          </div>
        </div>
      </section>

      <section className="pt-20 pb-20 md:pb-32">
      <div className="container">
        <div className="grid items-center gap-y-8 md:grid-cols-12 md:gap-x-10 lg:gap-x-20">
        <div className="md:col-span-7 md:col-start-1">
        <GatsbyImage image={data.whyus.childImageSharp.gatsbyImageData} className="rounded-2xl"/>

          </div>
        <div className="md:col-span-5 md:col-end-13">
            <h2>Why Dark Horse?</h2>
            <p>
            We believe you deserve a partner and an advocate, not someone who feels conflicted or lacks competence. We're obsessed with your success, and we have the expertise to help make it happen.
            </p>
            <ButtonWithIcon
              href="/about-us/"
              text="Meet Our People"
              altStyle={1}
            />
          </div>
          
          
        </div>
      </div>
    </section>
    
      <CallToAction />
      
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Global.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Global.jpg" }
    ) {
      publicURL
    }
    business: file(relativePath: { eq: "home/business.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 528)
      }
    }
    hero: file(relativePath: { eq: "home/1.0 Desktop Hero.jpg" }) {
      publicURL
    }
    challenges: file(relativePath: { eq: "home/Challenges.jpg" }) {
      childImageSharp {
      gatsbyImageData(layout: CONSTRAINED, width: 760)
      }
    }
    intro: file(
      relativePath: { eq: "home/Intro.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 616)
      }
    }
    two: file(
      relativePath: { eq: "home/IRS Section 280E.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 616)
      }
    }
    whyus: file(
      relativePath: { eq: "home/Why Dark Horse_.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 768)
      }
    }
    cultivators: file(
      relativePath: { eq: "home/Cultivators & Manufacturers.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 768)
      }
    }
    dispensaries: file(
      relativePath: { eq: "home/Dispensaries.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 768)
      }
    }
    industry: file(
      relativePath: { eq: "home/Overall Cannabis Industry.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 768)
      }
    }
    taxes: file(relativePath: { eq: "Business Solutions/Tax.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 746)
      }
    }
    keeping: file(relativePath: { eq: "Business Solutions/Bookkeeping-img.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 746)
      }
    }
    cfo: file(relativePath: { eq: "Business Solutions/CFO-img.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 746)
      }
    }
    personal: file(relativePath: { eq: "locations/personal.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 528)
      }
    }
    agriculture: file(relativePath: { eq: "home/Agriculture.svg" }) {
      publicURL
    }
    biotech: file(relativePath: { eq: "home/Bio-Tech.svg" }) {
      publicURL
    }
    cannabis: file(relativePath: { eq: "home/Cannabis.svg" }) {
      publicURL
    }
    construction: file(relativePath: { eq: "home/Construction.svg" }) {
      publicURL
    }
    eCommerce: file(relativePath: { eq: "home/eCommerce.svg" }) {
      publicURL
    }
    contentCreators: file(
      relativePath: { eq: "home/Governmental & Content Creators.svg" }
    ) {
      publicURL
    }
    governmental: file(relativePath: { eq: "home/Governmental.svg" }) {
      publicURL
    }
    manufacturingDistribution: file(
      relativePath: { eq: "home/Manufacturing & Distribution.svg" }
    ) {
      publicURL
    }
    medicalDental: file(relativePath: { eq: "home/Medical & Dental.svg" }) {
      publicURL
    }
    nonProfits: file(relativePath: { eq: "home/Non-Profits.svg" }) {
      publicURL
    }
    professionalServices: file(
      relativePath: { eq: "home/Professional Services.svg" }
    ) {
      publicURL
    }
    realEstate: file(relativePath: { eq: "home/Real Estate.svg" }) {
      publicURL
    }
    retail: file(relativePath: { eq: "home/Retail.svg" }) {
      publicURL
    }
    technology: file(relativePath: { eq: "home/Technology.svg" }) {
      publicURL
    }
    exchange: file(relativePath: { eq: "home/1031 Exchange.svg" }) {
      publicURL
    }
    stockCompensation: file(
      relativePath: { eq: "home/Stock Compensation.svg" }
    ) {
      publicURL
    }
    estateTrustPlanning: file(
      relativePath: { eq: "home/Estate & Trust Planning.svg" }
    ) {
      publicURL
    }
    cryptocurrencyNFTs: file(
      relativePath: { eq: "home/Cryptocurrency & NFTs.svg" }
    ) {
      publicURL
    }
    rentalProperties: file(relativePath: { eq: "home/Rental Properties.svg" }) {
      publicURL
    }
    foreignTax: file(relativePath: { eq: "home/Foreign Tax.svg" }) {
      publicURL
    }
    military: file(relativePath: { eq: "home/Military.svg" }) {
      publicURL
    }
    multiStateFilings: file(
      relativePath: { eq: "home/Multi-State Filings.svg" }
    ) {
      publicURL
    }
    orangeSquare: file(
      relativePath: { eq: "global/decorative-orange-square.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 160)
      }
    }
    orangeCrosses: file(
      relativePath: { eq: "global/decorative-orange-crosses.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 192)
      }
    }
    blueSquare: file(
      relativePath: { eq: "global/decorative-blue-square.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 160)
      }
    }
    blueCrosses: file(
      relativePath: { eq: "global/decorative-blue-crosses.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 192)
      }
    }
  }
`;

export default Page;
