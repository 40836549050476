import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "@emotion/styled";
import tw from "twin.macro";

import Slider from "../Slider/SliderLogos";

const StyledSlider = styled.div`
  .slick-slide {
    ${tw`text-center`}
    > div {
      ${tw`flex items-center justify-between space-x-4`}
    }
  }
  .slick-dots {
    ${tw`justify-center items-center space-x-3 flex! -bottom-12!`}
    li {
      ${tw`h-3 w-3 bg-primary-500/50 hover:bg-primary-500 mr-0 rounded-full transition-colors duration-300 ease-linear`}
      &.slick-active {
        ${tw`bg-primary-500`}
      }
    }
  }
`;

const Accolades = ({ className }) => {
  const data = useStaticQuery(graphql`
    {
      xero: file(
        relativePath: { eq: "repeating/badges/xero adviser certified.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 115)
        }
      }
      aicpa: file(relativePath: { eq: "repeating/badges/Group 128.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 115)
        }
      }
      entrepreneursOrg: file(
        relativePath: { eq: "repeating/badges/Group 130.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 132)
        }
      }
      cepa: file(relativePath: { eq: "repeating/badges/CEPA.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 115)
        }
      }
      cfp: file(relativePath: { eq: "repeating/badges/CFP.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 115)
        }
      }
      chfc: file(relativePath: { eq: "repeating/badges/CHFCLogo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 132)
        }
      }
      gusto: file(
        relativePath: { eq: "repeating/badges/Gusto-Gold-Partner-Badge 1.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 94)
        }
      }
      expensifyApprovedCertification: file(
        relativePath: {
          eq: "repeating/badges/ExpensifyApproved-Certification-badge 1.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 142)
        }
      }
      darkHorseCPAsCertification: file(
        relativePath: {
          eq: "repeating/badges/Dark_Horse_CPAs_2022_Certification_Badge 1.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 74)
        }
      }
      qbProAdvisor: file(
        relativePath: { eq: "repeating/badges/QB-ProAdvisor-Badge 1.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 97)
        }
      }
      mindbody: file(relativePath: { eq: "repeating/badges/Group 129.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 116)
        }
      }
      ctcGold: file(relativePath: { eq: "repeating/badges/ctc-gold 1.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 104)
        }
      }
      profitFirstCertifiedMaster: file(
        relativePath: {
          eq: "repeating/badges/profit-first-certified-master.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 104)
        }
      }
      wealthAbility: file(
        relativePath: { eq: "repeating/badges/wealth-ability.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 167)
        }
      }
    }
  `);

  return (
    <section className={`overflow-x-hidden ${className || "pb-20 md:pb-32"}`}>
      <div className="container">
        <StyledSlider className="relative">
          <Slider>
            <div>
              <GatsbyImage image={data.cepa.childImageSharp.gatsbyImageData} />
            </div>

            <div>
              <GatsbyImage image={data.cfp.childImageSharp.gatsbyImageData} />
            </div>

            <div>
              <GatsbyImage
                image={data.chfc.childImageSharp.gatsbyImageData}
              />
            </div>

            <div>
              <GatsbyImage image={data.xero.childImageSharp.gatsbyImageData} />
            </div>

            <div>
              <GatsbyImage image={data.aicpa.childImageSharp.gatsbyImageData} />
            </div>

            <div>
              <GatsbyImage
                image={data.entrepreneursOrg.childImageSharp.gatsbyImageData}
              />
            </div>
            <div>
              <GatsbyImage image={data.gusto.childImageSharp.gatsbyImageData} />
            </div>
            <div>
              <GatsbyImage
                image={
                  data.expensifyApprovedCertification.childImageSharp
                    .gatsbyImageData
                }
              />
            </div>
            <div>
              <GatsbyImage
                image={
                  data.darkHorseCPAsCertification.childImageSharp
                    .gatsbyImageData
                }
              />
            </div>
            <div>
              <GatsbyImage
                image={data.qbProAdvisor.childImageSharp.gatsbyImageData}
              />
            </div>
            <div>
              <GatsbyImage
                image={data.mindbody.childImageSharp.gatsbyImageData}
              />
            </div>
            <div>
              <GatsbyImage
                image={data.ctcGold.childImageSharp.gatsbyImageData}
              />
            </div>
            <div>
              <GatsbyImage
                image={
                  data.profitFirstCertifiedMaster.childImageSharp
                    .gatsbyImageData
                }
              />
            </div>
            <div>
              <GatsbyImage
                image={data.wealthAbility.childImageSharp.gatsbyImageData}
              />
            </div>
          </Slider>
        </StyledSlider>
        <div className="flex items-center justify-between space-x-4 text-center md:space-x-5"></div>
      </div>
    </section>
  );
};

export default Accolades;
